body {
  margin: 0;
  padding: 0;
  background-color: #e5e5e5;
}

.App {
  width: 100%;
  height: 100vh;
}
.appWrapper {
  margin-bottom: 40px;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
}
